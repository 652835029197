import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import {HttpErrorResponse} from '@angular/common/http';
import {AppState} from '../app.state';
import {authFeatureKey, State} from './auth.reducer';

const getError = (state: State): HttpErrorResponse | null => state.error;
const getIsLoading = (state: State): boolean => state.isLoading;

export const selectAuthState: MemoizedSelector<AppState, State> = createFeatureSelector(authFeatureKey);

export const selectError: MemoizedSelector<AppState, HttpErrorResponse | null> = createSelector(selectAuthState, getError);
export const selectIsLoading: MemoizedSelector<AppState, boolean> = createSelector(selectAuthState, getIsLoading);
