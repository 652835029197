import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LanguageInterceptor} from './language.interceptor';
import {WithCredentialsInterceptor} from './with-credentials.interceptor';
import {Provider} from '@angular/core';
import {ApiInterceptor} from './api.interceptor';
import {ErrorInterceptor} from './error.interceptor';
import {TokenInterceptor} from './token.interceptor';

export const httpInterceptorProviders: Provider = [
  {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
];
