import { createReducer, on } from '@ngrx/store';
import {heartbeat, heartbeatFailure, heartbeatSuccess, login, loginFailure, loginSuccess, logout} from './auth.actions';


export const authFeatureKey = 'auth';

export interface State{
  isAuthenticated: boolean;
  isHeartbeatStarted: boolean;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  isAuthenticated: false,
  isHeartbeatStarted: false,
  isLoading: false,
  error: null
};


export const reducer = createReducer(
  initialState,
  on(login, (state: State) => {
    return {
      ...state,
      isLoading: true,
      error: null
    };
  }),
  on(loginSuccess, (state: State) => {
    return {
      ...state,
      isLoading: false,
      error: null
    };
  }),
  on(loginFailure, (state: State, { error }) => {
    return {
      ...state,
      isLoading: false,
      error
    };
  }),
  on(logout, (state: State) => {
    return {
      ...state,
      isLoading: false,
      error: null
    };
  }),
  on(heartbeat, (state: State) => {
    return {
      ...state
    };
  }),
  on(heartbeatSuccess, (state: State) => {
    return {
      ...state,
      isAuthenticated: true,
      isHeartbeatStarted: true
    };
  }),
  on(heartbeatFailure, (state: State) => {
    return {
      ...state,
      isAuthenticated: false,
      isHeartbeatStarted: false
    };
  })
);

