import {ActionReducer} from '@ngrx/store';
import {AuthActions} from './auth';


export function handleLogout(rootReducer: ActionReducer<any>): ActionReducer<any>{
  return (state: any, action: any) => {
    if (action.type === AuthActions.heartbeatFailure.type){
      state = undefined;
    }
    return rootReducer(state, action);
  };
}
