import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {PostAuthLoginDto} from '../../interfaces/dto/auth/post-auth-login-dto';
import {GetAuthHeartbeatPayload} from '../../interfaces/payload/auth/get-auth-heartbeat-payload';
import {PostLoginPayload} from '../../interfaces/payload/login/post-login-payload';
import {Payload} from '../../interfaces/payload/payload';

@Injectable({
  providedIn: 'root'
})
export class UserAuthApiService {

  constructor(
    private http: HttpClient
  ) { }

  postAuthLogin(postLoginDto: PostAuthLoginDto): Observable<Payload<PostLoginPayload>>{
    return this.http.post<Payload<PostLoginPayload>>('/user/auth/login', postLoginDto).pipe(first());
  }

  postAuthLogout(): Observable<Payload<null>>{
    return this.http.post<Payload<null>>('/user/auth/logout', {}).pipe(first());
  }

  getHeartbeat(): Observable<Payload<GetAuthHeartbeatPayload>>{
    return this.http.get<Payload<GetAuthHeartbeatPayload>>('/user/auth/heartbeat').pipe(first());
  }
}
