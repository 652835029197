import { createAction, props } from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {PostAuthLoginDto} from '../../interfaces/dto/auth/post-auth-login-dto';

export const login = createAction(
  '[Auth] Login',
  props<{postAuthLoginDto: PostAuthLoginDto}>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success'
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{error: HttpErrorResponse}>()
);

export const logout = createAction(
  '[Auth] Logout',
);

export const heartbeat = createAction(
  '[Auth] Heartbeat'
);

export const heartbeatFailure = createAction(
  '[Auth] Heartbeat Failure',
  props<{error: HttpErrorResponse}>()
);

export const heartbeatSuccess = createAction(
  '[Auth] Heartbeat Success'
);
