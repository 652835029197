import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {CustomToastComponent} from '../shared/containers/custom-toast/custom-toast.component';
import {AuthStoreModule} from '../store/auth';

@NgModule({
  declarations: [
    CustomToastComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      maxOpened: 1,
      preventDuplicates: true,
      toastComponent: CustomToastComponent,
      timeOut: 3000,
      extendedTimeOut: 5000,
    }),
    AuthStoreModule
  ]
})
export class CoreModule { }
