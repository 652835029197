import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { handleLogout } from './handleLogout';
import { handleUndo } from './handleUndo';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({
      router: routerReducer
    }, {
      metaReducers: [handleUndo, handleLogout]
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ]
})
export class AppStoreModule { }
