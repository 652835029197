import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AuthFacadeService} from '../store/auth/auth-facade.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadShopGuard implements CanLoad {

  constructor(
    private authFacadeService: AuthFacadeService,
    private router: Router
  ) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree>{

    return this.authFacadeService.isLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn){
          return of(true);
        }
        return of(this.router.createUrlTree(['/auth']));
      })
    );
  }
}
