import {Action, ActionReducer, INIT, UPDATE} from '@ngrx/store';
import {undo} from './app.action';
import * as equal from 'fast-deep-equal';

let executedActions: Array<Action> = [];
let initialState;
let bufferSize = 100;

export function configureBufferSize(size: number): void {
  bufferSize = size;
}

export function handleUndo(rootReducer: ActionReducer<any>): ActionReducer<any>{
  return (state: any, action: any) => {
    if (action.type === undo.type) {
      // if the action is UNDO_ACTION,
      // then call all the actions again on the rootReducer,
      // except the one we want to rollback
      let newState: any = initialState;
      executedActions = executedActions.filter(eAct => !equal(eAct, action.action));

      // update the state for every action until we get the
      // exact same state as before, but without the action we want to rollback
      executedActions.forEach(executedAction => newState = rootReducer(newState, executedAction));

      return newState;
    }
    if (!(action.type === INIT || action.type === UPDATE)) {
      // push every action that isn't an UNDO_ACTION, STORE_INIT_ACTION, or STORE_UPDATE_ACTION to the executedActions property
      executedActions.push(action);
    }
    const updatedState = rootReducer(state, action);
    if (executedActions.length === bufferSize + 1) {
      const firstAction = executedActions[0];
      // calculate the state x (buffersize) actions ago
      initialState = rootReducer(initialState, firstAction);
      // keep the correct actions
      executedActions = executedActions.slice(1, bufferSize + 1);
    }
    return updatedState;
  };
}
