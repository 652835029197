import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private title = new BehaviorSubject(null);
  private keywords = new BehaviorSubject(null);
  private description = new BehaviorSubject(null);

  title$ = this.title.asObservable().pipe(
    filter((title) => !!title),
    map((title) => this.titleService.setTitle(title))
  );
  keywords$ = this.keywords.asObservable().pipe(
    filter((content) => !!content),
    map((content) => this.metaService.updateTag({ property: 'keywords', content }))
  );
  description$ = this.description.asObservable().pipe(
    filter((content) => !!content),
    map((content) => this.metaService.updateTag({property: 'description', content}))
  );

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {}

  setAll(content: string): void{
    this.setTitle(content);
    this.setKeywords(content);
    this.setDescription(content);
  }
  setTitle(title: string): void{
    this.title.next(title);
  }
  setKeywords(keywords: string): void{
    this.keywords.next(keywords);
  }
  setDescription(description: string): void{
    this.description.next(description);
  }
}
