import { NgModule } from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {authFeatureKey, reducer} from './auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';



@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(authFeatureKey, reducer),
    EffectsModule.forFeature([AuthEffects])
  ]
})
export class AuthStoreModule { }
