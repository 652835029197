import { registerLocaleData, ViewportScroller } from '@angular/common';
import localCH from '@angular/common/locales/de-CH';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { filter, pairwise } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './interceptors';
import { AppStoreModule } from './store/app-store.module';


registerLocaleData(localCH);


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    AppStoreModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
  ],
  providers: [
    httpInterceptorProviders,
    {provide: LOCALE_ID, useValue: 'de-CH'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'CHF'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    this.router.events.pipe(
      filter( ( e ): e is Scroll => e instanceof Scroll ),
      pairwise()
    ).subscribe(([prev, current]) => {
      if (current.position){
        // backward navigation
        this.viewportScroller.scrollToPosition( current.position );
      } else if ( current.anchor ) {
        // anchor navigation
        this.viewportScroller.scrollToAnchor( current.anchor );
      } else {
        // forward navigation
        if ( (prev.routerEvent.urlAfterRedirects.split( '?' )[ 0 ]) !== current.routerEvent.urlAfterRedirects.split( '?' )[ 0 ] ) {
          // Routes don't match, this is actual forward navigation
          // Default behavior: scroll to top
          this.viewportScroller.scrollToPosition( [0, 0] );
        }
      }
    });
  }
}
