import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserAuthApiService } from '../../api/user/user-auth-api.service';
import { PostAuthLoginDto } from '../../interfaces/dto/auth/post-auth-login-dto';
import { GetAuthHeartbeatPayload } from '../../interfaces/payload/auth/get-auth-heartbeat-payload';
import { Payload } from '../../interfaces/payload/payload';
import { AppState } from '../app.state';
import { AuthActions, AuthSelectors } from './index';

@Injectable({
  providedIn: 'root'
})
export class AuthFacadeService {

  error$: Observable<HttpErrorResponse | null> = this.store.select(AuthSelectors.selectError);
  isLoading$: Observable<boolean> = this.store.select(AuthSelectors.selectIsLoading);
  selectAuthState$ = this.store.select(AuthSelectors.selectAuthState);

  constructor(
    private store: Store<AppState>,
    private userAuthApiService: UserAuthApiService
  ) {
  }

  login(postAuthLoginDto: PostAuthLoginDto): void {
    this.store.dispatch({type: AuthActions.login.type, postAuthLoginDto});
  }

  logout(): void {
    this.store.dispatch({type: AuthActions.logout.type});
  }

  heartbeat(): void {
    this.store.dispatch({type: AuthActions.heartbeat.type});
  }

  isLoggedIn(): Observable<boolean> {
    if (localStorage.getItem('token')){
      return this.selectAuthState$.pipe(
        switchMap((data) => {
          if (!data.isAuthenticated && !data.isHeartbeatStarted) {
            return this.getHeartbeat().pipe(
              map(() => true),
              catchError(() => of(false))
            );
          }
          return of(data.isAuthenticated);
        })
      );
    }
    return of(false);
  }

  getHeartbeat(): Observable<Payload<GetAuthHeartbeatPayload>> {
    return this.userAuthApiService.getHeartbeat();
  }
}
