import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {UserAuthApiService} from '../../api/user/user-auth-api.service';
import {AppState} from '../app.state';
import {heartbeat, heartbeatFailure, heartbeatSuccess, login, loginFailure, loginSuccess, logout} from './auth.actions';
import {catchError, delay, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';


@Injectable()
export class AuthEffects {

  login$ = createEffect(() => this.actions$.pipe(
    ofType(login.type),
    mergeMap(({postAuthLoginDto}) => this.userAuthApiService.postAuthLogin(postAuthLoginDto).pipe(
      switchMap((payload) => {
        localStorage.setItem('token', payload.data.token);
        localStorage.setItem('expirationInterval', payload.data.expirationInterval.toString(10));
        localStorage.setItem('expires', payload.data.expires.toString());
        return [
          {type: loginSuccess.type, payload},
          {type: heartbeat.type}
        ];
      }),
      catchError((error) => of({type: loginFailure.type, error}))
    ))
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loginSuccess.type),
    tap(() => this.router.navigate(['/shop']))
  ), {dispatch: false});

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout.type),
    switchMap(() => this.userAuthApiService.postAuthLogout().pipe(
      map(() => ({type: heartbeatFailure.type})),
      catchError(() => of({type: heartbeatFailure.type}))
    ))
  ));

  heartbeat$ = createEffect(() => this.actions$.pipe(
    ofType(heartbeat.type),
    mergeMap(() => this.userAuthApiService.getHeartbeat().pipe(
      map((payload) => {
        localStorage.setItem('expirationInterval', payload.data.expirationInterval.toString(10));
        localStorage.setItem('expires', payload.data.expires.toString());
        return {type: heartbeatSuccess.type};
      }),
      catchError((error) => of({type: heartbeatFailure.type, error}))
    ))
  ));

  heartbeatSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(heartbeatSuccess.type),
    switchMap(() => {
      const ttl: number = parseInt(localStorage.getItem('expirationInterval'), 10);
      return of({type: heartbeat.type}).pipe(delay(ttl));
    })
  ));

  heartbeatFailure$ = createEffect(() => this.actions$.pipe(
    ofType(heartbeatFailure.type),
    tap(({type}) => {
      localStorage.removeItem('token');
      localStorage.removeItem('expires');
      localStorage.removeItem('expirationInterval');
      this.router.navigate(['/auth/login']);
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private userAuthApiService: UserAuthApiService
  ) {
  }

}
