import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import * as WebFont from 'webfontloader';
import {MetaService} from './services/meta.service';
import {AuthFacadeService} from './store/auth/auth-facade.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  constructor(
    private authFacadeService: AuthFacadeService,
    private metaService: MetaService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    WebFont.load({
      custom: {
        families: [
          'Fira+Sans',
        ],
        urls: [
          'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap'
        ]
      }
    });

    if (localStorage.getItem('token')) {
      this.authFacadeService.heartbeat();
    }
    this.metaService.title$.pipe(takeUntil(this.destroyed$)).subscribe();
    this.metaService.keywords$.pipe(takeUntil(this.destroyed$)).subscribe();
    this.metaService.description$.pipe(takeUntil(this.destroyed$)).subscribe();

    this.router.events.pipe(
      takeUntil(this.destroyed$),
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route) => route.data))
      .subscribe((data) => {
        if (data.meta){
          // setTitle
          const title = data.meta.title || null;
          const keywords = data.meta.keywords || title;
          const description = data.meta.description || title;

          this.metaService.setTitle(title);
          this.metaService.setKeywords(keywords);
          this.metaService.setDescription(description);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
